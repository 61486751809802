.cb-wrap-header {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 59px;
  left: 0px;
  background-color: #E9E9F9;
  padding-top: 19px;
  padding-bottom: 3px;
  width: 285px;
  padding-bottom: 100px;
  z-index: 10;
}

.cb-wrap-header-first {
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-family: Urbanist, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: left;
  }
}

.cb-wrap-drag-text {
  font-family: Urbanist, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
}

.cb-wrap-header-icons {
  display: flex;
  gap: 8px;
}

@media screen and (min-width: 761px) and (max-width: 1235px) {
  .cb-wrap-header {
    display: flex;
    flex-direction: column;
    // gap: 35px;
    padding-bottom: 100px;
    position: fixed;
    top: 59px;
    left: 0px;
    z-index: 102;
    background-color: #E9E9F9;
    padding-top: 19px;
    padding-bottom: 3px;
    width: 233px;



  }
}

@media screen and (min-width: 550px) and (max-width: 761px) {
  .cb-wrap-header {
    display: flex;
    flex-direction: column;
    // gap: 35px;
    padding-bottom: 100px;
    position: fixed;
    top: 59px;
    left: 0px;
    z-index: 102;
    background-color: #E9E9F9;
    padding-top: 19px;
    padding-bottom: 3px;
    width: 190px;



  }


}


@media screen and (min-width: 500px) and (max-width: 550px) {
  .cb-wrap-header {
    display: flex;
    flex-direction: column;
    // gap: 35px;
    padding-bottom: 70px;
    position: fixed;
    top: 59px;
    left: 0px;
    z-index: 102;
    background-color: #E9E9F9;
    padding-top: 19px;
    padding-bottom: 3px;
    width: 174.825px;



  }

}


@media screen and (min-width: 0px) and (max-width: 500px) {
  .cb-wrap-header {
    display: flex;
    flex-direction: column;
    // gap: 35px;
    padding-bottom: 80px;
    position: fixed;
    top: 59px;
    left: 0px;
    z-index: 102;
    background-color: #E9E9F9;
    padding-top: 19px;
    padding-bottom: 3px;
    width: 80px;



  }

}