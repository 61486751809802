@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@tailwind base;
@layer base {
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button,
    input[type='number'] {
        -webkit-appearance: none;
        -moz-appearance: textfield !important;
    }
}

@tailwind components;
@tailwind utilities;

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
html,
body {
    font-family: 'Urbanist', sans-serif;
}

.frmb-control ui-sortable {
    border: none;
}

.frmb-control.ui-sortable > * {
    font-size: 14px;
    font-weight: 600;
}

.form-wrap.form-builder .frmb-control li {
    box-shadow: none;
}

li[type='additionalParticipants'] .field-label,
li[type='additionalMinors'] .field-label,
li[type='richTextEditor'] .field-label,
li[type='address'] .field-label {
    display: none !important;
}

#waiver-editor
    :is(
        [type='date'],
        [type='datetime-local'],
        [type='email'],
        [type='month'],
        [type='number'],
        [type='password'],
        [type='search'],
        [type='tel'],
        [type='text'],
        [type='timeComponent'],
        [type='time'],
        [type='url'],
        [type='week'],
        select,
        textarea
    ) {
    border: 1px solid #6b7280;
}

.tox-tinymce {
    margin-top: 30px;
}

.common h1,
header {
    font-weight: 700;
    font-size: 30px;
}

.form-wrap.form-builder .frmb .form-elements .input-wrap > input[type='checkbox'] {
    width: 1rem;
    height: 1rem;
}

.additionalParticipants-field :is(.label-wrap, .description-wrap, .placeholder-wrap, .value-wrap),
.additionalMinors-field :is(.label-wrap, .description-wrap, .placeholder-wrap, .value-wrap),
.capturePhoto-field :is(.description-wrap, .placeholder-wrap, .value-wrap),
.filesUpload-field :is(.description-wrap, .placeholder-wrap, .value-wrap),
.max-w-4xl.mx-auto.my-6.common label.formbuilder-richTextEditor-label {
    display: none !important;
}

.form-wrap.form-builder .frmb .form-elements .false-label:first-child,
.form-wrap.form-builder .frmb .form-elements label:first-child {
    white-space: pre-wrap !important;
}

.sign {
    overflow: hidden;
}

.tooltip-element {
    display: none !important;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;
}
