.Button-Div-Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 997px;
  margin: 0 auto;
}

.Button-div1 {
  font-family: Urbanist, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: center;
  color: #272d39;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Button-div2 {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.btn2Span {
  font-family: Urbanist, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  color: #272d39;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn2Span2 {
  border-radius: 50px 50px 50px 50px;
  font-family: Urbanist, sans-serif;
  font-size: 14px;
  font-weight: 800;
  width: 90px;
  height: 35px;
  color: #ffffff;
  background-color: #2f2a60;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.form-builder-header {
  font-family: Urbanist, sans-serif;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #272d39;
}

.form-wrap.form-builder.formbuilder-embedded-bootstrap .form-control {
  height: 43px !important;
}

@media screen and (min-width: 1920px) and (max-width: 2260px) {
  .Button-div2 {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}

@media screen and (min-width: 1450px) and (max-width: 1920px) {
  .Button-div2 {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}

@media screen and (min-width: 1236px) and (max-width: 1450px) {
  .Button-div2 {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1235px) {
  .Button-div2 {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}

@media screen and (min-width: 761px) and (max-width: 768px) {
  .Button-div2 {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}

@media screen and (min-width: 720px) and (max-width: 761px) {
  .Button-div2 {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}

@media screen and (min-width: 500px) and (max-width: 720px) {
  .Button-div2 {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}

@media screen and (min-width: 0px) and (max-width: 500px) {
  .Button-div2 {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}