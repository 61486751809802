@import "./mixins/mixins";

@import "./component/wrapper";
@import "./component/controls";
@import "./component/stage";
@import "./component/fields";

body {
  margin: 0;
  font-family: "Inter", sans-serif;
}
