thead th:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

/* Apply border-radius to the last th in the first row */
thead th:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}
