// Drop Area
.stage-wrap {
  margin-top: 20px !important;
  position: static !important;
  min-height: unset !important;
  height: calc(100vh - 200px);
  width: 90% !Important;
  max-width: 997px !important;
  overflow-y: scroll !important;
  background-color: #fff !important;
  border-radius: 10px;
  border: 1px solid #dcd3d3 !important;
  padding: 28px 20px !important;
}

@media screen and (max-width: 1235px) {
  .stage-wrap {
    border-radius: 8px;
  }
}

@media screen and (max-width: 600px) {
  .stage-wrap {
    border-radius: 8px;
  }
}

.form-wrap.form-builder {
  display: flex !important;
  justify-content: center;
  width: 100% !important;
  flex-grow: 1;
  background-color: transparent !important;
  flex-direction: unset !important;
}

.ui-state-highlight {
  background-color: #d4d3f0 !important;
  border-radius: 10px !important;
  border: none !important;
}

.empty:has(.ui-state-highlight) {
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  .empty {
    font-size: 12px;
    text-wrap: wrap;
  }
}