.form-wrap.form-builder .field-label,
.form-wrap.form-builder .legend {
  display: inline-block !important;
  color: #000 !important;
  font-family: "Urbanist", sans-serif;
  font-weight: 600 !important;
  line-height: 19px !important;
  margin-bottom: 12px !important;
  margin-left: 7px !important;
}

.form-wrap.form-builder input[type="text"],
.form-wrap.form-builder input[type="email"],
.form-wrap.form-builder input[type="date"],
.form-wrap.form-builder input[type="number"],
.form-wrap.form-builder input[type="tel"],
.form-wrap.form-builder input[type="time"],
.form-wrap.form-builder select {
  width: 100% !important;
  max-width: 300px !important;
  border-radius: 25px !important;
  padding: 8px 20px !important;
  padding-bottom: 11px !important;
  background-color: #f5f4ff !important;
  border: none !important;
  box-shadow: none !important;
  margin-bottom: 10px !important;
}

.form-wrap.form-builder .form-wrap.form-builder input[type="text"]::placeholder,
.form-wrap.form-builder input[type="email"]::placeholder,
.form-wrap.form-builder input[type="date"]::placeholder,
.form-wrap.form-builder input[type="number"]::placeholder,
.form-wrap.form-builder input[type="tel"],
.form-wrap.form-builder input[type="time"],
.form-wrap.form-builder select,
.form-wrap.form-builder option {
  color: #8a8888 !important;
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
}

.form-wrap.form-builder select {
  padding: 12px 20px !important;
}

li[type="address"] .tooltip-element {
  display: none !important;
}

.form-wrap.form-builder .electronic-consent {
  display: flex;
  align-items: start;
  gap: 5px;

  input[type="checkbox"] {
    accent-color: #7e79fa !important;
  }

  p {
    margin-top: -4px;
    font-size: 14px;
    color: #3a3939;
  }
}

.form-wrap.form-builder button {
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  background-color: #f5f4ff !important;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  margin-right: 10px;
}

.wb-wrap-forms {
  display: flex;
  min-height: 100vh;
  max-height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
  background-color: #F8F8FF;
  padding-top: 0px !important;
  margin-top: 0px !important;
  // padding-top: 50px;
  // padding-bottom: 40px;
}



@media screen and (min-width: 1236px) {
  // .wb-wrap-forms {
  //   width: calc(100% - 285px);
  // }

  .form-header-text {
    width: 100%;
    padding-left: 14%;
  }
}

@media screen and (min-width: 550px) and (max-width: 761px) {
  // .wb-wrap-forms {
  //   width: calc(100% - 190px);
  // }

  .form-header-text {
    width: 100%;
    padding-left: 10%;
  }
}

@media screen and (min-width: 500px) and (max-width: 550px) {
  // .wb-wrap-forms {
  //   width: calc(100% - 217px);
  // }

  .form-header-text {
    width: 100%;
    padding-left: 8%;
  }

}

@media screen and (min-width: 0px) and (max-width: 500px) {
  // .wb-wrap-forms {
  //   width: calc(100% - 80px);
  // }

  .cb-wrap-header-first {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .cb-wrap-header-icons {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .form-header-text {
    width: 100%;
    padding-left: 30px;
  }
}

@media screen and (min-width: 0px) and (max-width: 720px) {
  .ButtonDiv {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Buttondiv2 {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

@media screen and (min-width: 720px) and (max-width: 761px) {
  .ButtonDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0px 70px 0px 70px;
  }

  .Buttondiv2 {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}