.header-font-style {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 23.38px;
    color: rgba(15, 23, 41, 1);
}

.customImg {
    filter: invert(100%);
}
