.frmb-control {
  display: grid;
  grid-template-columns: repeat(2, 117px);
  gap: 12px;

  li {
    height: 130px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center !important;
    text-wrap: wrap !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    transition: background-color 0.3s;

    &[data-type="additionalParticipants"],
    &[data-type="additionalMinors"],
    &[data-type="signature"],
    &[data-type="address"],
    &[data-type="primaryAdultParticipant"],
    &[data-type="richTextEditor"],
    &[data-type="electronicSignatureConsent"],
    &[data-type="capturePhoto"],
    &[data-type="timeComponent"],
    &[data-type="filesUpload"],
    &[data-type="editable"] {
      span {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #2c2b2b;
        text-wrap: wrap;

        .control-icon {
          margin: 0 !important;
          display: none !important;
        }
      }
    }

    &[data-type="additionalParticipants"]::before,
    &[data-type="additionalMinors"]::before,
    &[data-type="signature"]::before,
    &[data-type="address"]::before,
    &[data-type="primaryAdultParticipant"]::before,
    &[data-type="richTextEditor"]::before,
    &[data-type="electronicSignatureConsent"]::before,
    &[data-type="capturePhoto"]::before,
    &[data-type="timeComponent"]::before,
    &[data-type="filesUpload"]::before,
    &[data-type="editable"]::before {
      position: relative;
      top: 5px;
    }

    &[data-type="additionalParticipants"]::before {
      content: url("../icons/additional-participants.svg");
    }

    &[data-type="additionalMinors"]::before {
      content: url("../icons/additonal-minor.svg");
    }

    &[data-type="signature"]::before {
      content: url("../icons/signature.svg");
    }

    &[data-type="address"]::before {
      content: url("../icons/location.svg");
    }

    &[data-type="primaryAdultParticipant"]::before {
      content: url("../icons/participant.svg");
    }

    &[data-type="richTextEditor"]::before {
      content: url("../icons/text-area.svg");
    }

    &[data-type="electronicSignatureConsent"]::before {
      content: url("../icons/save.svg");
    }

    &[data-type="capturePhoto"]::before {
      content: url("../icons/image.svg");
    }

    &[data-type="filesUpload"]::before {
      content: url("../icons/upload.svg");
    }

    &[data-type="timeComponent"]::before {
      content: url("../icons/time.png");
    }

    &[data-type="editable"]::before {
      content: url("../icons/participant.svg");
    }

    &:first-child {
      border-radius: 10px !important;
    }

    &:last-child {
      border-radius: 10px !important;
    }

    &::before {
      display: block;
      margin: 0px !important;
      margin-bottom: 7px !important;
      margin-top: 13px !important;
      font-size: 24px !important;
      color: #a8a8bd;
    }

    &:hover {
      background-color: #b3b0ff !important;
      color: #fff;

      &::before {
        color: #fff;
      }
    }

    &::after {
      content: url("../icons/dots.svg");

      position: absolute;
      top: 11px;
    }

    @include breakpoint("phone-lrg") {
      span {
        visibility: visible !important;
      }
    }
  }
}

@media screen and (max-width: 1235px) {
  .frmb-control {
    grid-template-columns: repeat(2, 100px);
    gap: 7px;

    li {
      height: 100px;
      border-radius: 8px;
      font-size: 12px;
      line-height: 14px;

      &:first-child {
        border-radius: 8px !important;
      }

      &:last-child {
        border-radius: 8px !important;
      }

      &::before {
        font-size: 16px !important;
      }

      &::after {
        content: url("../icons/dots-small.svg");
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .frmb-control {
    grid-template-columns: repeat(2, 80px);
    gap: 5px;
    li {
      height: 90px;
      font-size: 10px;
      line-height: 12px;
      padding: 0;
    }
  }
}

@media screen and (max-width: 550px) {
  .frmb-control {
    grid-template-columns: 1fr;
  }
}
