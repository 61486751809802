// Controls Side bar
.cb-wrap {
  max-width: 285px !important;
  padding: 15px;
  z-index: 20;
  position: fixed;
  top: 153px;
  left: 0;
  bottom: 0;

  background-color: #e9e9f9;

  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 10px;
    /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #dfdfe5;
    /* Color of the track */
  }

  &::-webkit-scrollbar-thumb {
    background: #7e79fa;
    /* Color of the thumb */
    border-radius: 5px;
    /* Rounded corners */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #5b56f8;
    /* Color of the thumb on hover */
  }
}

.cb-wrap ul {
  margin-top: 0 !important;
}

.cb-wrap-header {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.cb-wrap-header-first {
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-family: Urbanist, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: left;
  }
}

.cb-wrap-drag-text {
  font-family: Urbanist, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
}

.cb-wrap-header-icons {
  display: flex;
  gap: 8px;
}

@media screen and (max-width: 1235px) {
  .cb-wrap {
    width: 233px !important;
    min-width: 233px !important;
    padding: 4px;
  }
}

@media screen and (max-width: 760px) {
  .cb-wrap {
    width: 190px !important;
    min-width: 190px !important;
    min-height: 100vh;
    top: 190px;
  }
}

@media screen and (max-width: 550px) {
  .cb-wrap {
    width: 150px !important;
    max-width: fit-content !important;
    min-width: fit-content !important;
    top: 190px;

    &::-webkit-scrollbar {
      width: 6px;
      /* Width of the scrollbar */
    }
  }
}

@media screen and (max-width: 500px) {
  .cb-wrap {
    width: 80px !important;
    max-width: fit-content !important;
    min-width: 80px !important;
    top: 307px;

    &::-webkit-scrollbar {
      width: 6px;
      /* Width of the scrollbar */
    }
  }
}